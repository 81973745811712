import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '0, 0, 112',
		'primary-dark': '0, 0, 65',
		'accent': '255, 214, 0',
		'accent-plus': '0, 0, 0',
	},
});
